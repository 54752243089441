import React from 'react'
import './Footer.scss'
import Logo from '../../../public/assets/logo.svg'
import SocialLinkedin from '../../../public/assets/social-linkedin.svg'
import SocialYoutube from '../../../public/assets/social-youtube.svg'
import MenuItem from '../MenuItem/MenuItem'
import { locationUrl as locationUrlEnum } from '../../pages/ContactPage/ContactPageData'
import IconLink from '../../components/IconLink/IconLink'

interface IProps {
    locationUrl?: locationUrlEnum
}

const Footer: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { locationUrl = locationUrlEnum.BELGRADE } = props

    const scrollToTop = () => window.scrollTo(0, 0)

    return (
        <div className="footer">
            <div className="footer-container container">
                <div className="first-row">
                    <Logo />

                    <div className="social">
                        <IconLink link="https://www.linkedin.com/company/nanosecond" target="blank" icon={<SocialLinkedin />} />
                        <IconLink link="https://www.youtube.com/channel/UCFETaWec253HgCcitpS1Ozw" target="blank" icon={<SocialYoutube />} />
                    </div>
                </div>

                <div className="second-row">
                    <MenuItem pageUrl={'/'} label={'Home'} onClick={scrollToTop} />
                    <MenuItem pageUrl={'/careers'} label={'Careers'} onClick={scrollToTop} />
                    <MenuItem pageUrl={`/contact/${locationUrl}`} label={'Contact'} onClick={scrollToTop} />
                </div>

                <div className="third-row">
                    <a href="mailto:info@inceptive.io">info@inceptive.io</a>
                    <a href="mailto:careers@inceptive.io">careers@inceptive.io</a>
                    <a href="mailto:security@inceptive.io">security@inceptive.io</a>
                </div>

                <div className="copyrights-info">
                    <p>
                        Copyright © 2025 <br />
                        Inceptive. All rights received.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Footer
