import React, { LegacyRef } from 'react'
import JobItem from '../JobItem/JobItem'
import './JobList.scss'

const jobDesc: [string, string][] = [
    ['/core-platform-cpp', 'Core Platform C++'],
    ['/core-platform-go', 'Core Platform GO'],
    ['/fe-developer', 'Frontend Developer'],
    ['/site-reliability-engineer', 'Site Reliability Engineer'],
    ['/talent-wanted', 'Missing an offer']
]

const JobList = React.forwardRef((props, ref: LegacyRef<HTMLDivElement>): JSX.Element => {
    return (
        <div className="jobs-list" ref={ref}>
            {jobDesc.map((x, i) => (
                <JobItem key={i} jobTitle={x[1]} jobUrl={x[0]} />
            ))}
        </div>
    )
})
JobList.displayName = 'JobList'

export default JobList
