import React, { ReactFragment } from 'react'
import { match } from 'ts-pattern'
import JobDetailsList from '../../components/JobDetailsList/JobDetailsList'

export enum JobUrl {
    CORE_PLATFORM_CPP = 'core-platform-cpp',
    CORE_PLATFORM_GO = 'core-platform-go',
    FE_DEVELOPER = 'fe-developer',
    SR_ENGINEER = 'site-reliability-engineer',
    TALENT_WANTED = 'talent-wanted'
}

export enum JobLocation {
    BELGRADE = 'Belgrade'
}

export enum EnvironmentType {
    ON_SITE = 'On-site'
}

export enum EmploymentType {
    FULL_TIME = 'Full-time'
}

export type JobUrlType = JobUrl | undefined

export const determineJobTitle = (jobUrl: JobUrlType): string =>
    match<JobUrlType, string>(jobUrl)
        .with(JobUrl.CORE_PLATFORM_CPP, () => 'Core Platform C++')
        .with(JobUrl.CORE_PLATFORM_GO, () => 'Core Platform Go')
        .with(JobUrl.FE_DEVELOPER, () => 'Frontend Developer')
        .with(JobUrl.SR_ENGINEER, () => 'Site Reliability Engineer')
        .otherwise(() => 'Missing an offer?')

const corePlatformCppFragment: ReactFragment = (
    <>
        Looking for candidates with a desire to work on building real-time low latency software. Any experience with networking, reliability
        protocols and generic programming is considered a plus.
    </>
)

const corePlatformCppDescription: ReactFragment = (
    <>
        <JobDetailsList
            listTitle={'General'}
            listItems={
                <>
                    <p>Reliable low latency protocols</p>
                    <p>Platform agnostic data serialization</p>
                </>
            }
        />
        <JobDetailsList
            listTitle={'Technical'}
            listItems={
                <>
                    <ul>
                        <li>C++ specific</li>
                        <li>
                            <ul className="nested-list">
                                <li>ASIO (Networking TS)</li>
                                <li>Range-V3 (Ranges TS)</li>
                                <li>Concepts (Concepts TS)</li>
                                <li>&#123; fmt &#125; (Formatting TS)</li>
                                <li>LLFIO (low level file I/O library)</li>
                                <li>Clang Tidy</li>
                            </ul>
                        </li>
                        <li>TCP Networking</li>
                        <li>Linux operating system API</li>
                    </ul>
                </>
            }
        />
        <JobDetailsList
            listTitle={'Responsibilities'}
            listItems={
                <>
                    <p>Unit testing and micro benchmarking</p>
                    <p>Continuous integration</p>
                    <p>Peer code review</p>
                    <p>Bazel build system</p>
                    <p>Dockerized services</p>
                </>
            }
        />
    </>
)

const corePlatformGoFragment: ReactFragment = (
    <>
        Looking for candidates with a desire to work on building highly-scalable web services. Any experience with real-time data
        distribution, user authentication and identity protocols and functional programming is considered a plus.
    </>
)

const corePlatformGoDescription: ReactFragment = (
    <>
        <JobDetailsList
            listTitle={'General'}
            listItems={
                <>
                    <p>Reliable low latency protocols</p>
                    <p>Platform agnostic data serialization</p>
                </>
            }
        />
        <JobDetailsList
            listTitle={'Technical'}
            listItems={
                <>
                    <ul>
                        <li>Go specific</li>
                        <li>
                            <ul className="nested-list">
                                <li>GoRoutines and GoChannels</li>
                                <li>Gorilla libraries for creating web services</li>
                                <li>GolangCI-Lint</li>
                            </ul>
                        </li>
                        <li>HTTP and Websocket services</li>
                        <li>MongoDB</li>
                        <li>OAuth2 &#40;OIDC&#41;</li>
                        <li>Grafana and Prometheus for test data visualization</li>
                    </ul>
                </>
            }
        />
        <JobDetailsList
            listTitle={'Responsibilities'}
            listItems={
                <>
                    <p>Unit testing and micro benchmarking</p>
                    <p>Continuous integration</p>
                    <p>Peer code review</p>
                    <p>Bazel build system</p>
                    <p>Dockerized services</p>
                </>
            }
        />
    </>
)

const feDevFragment: ReactFragment = (
    <>
        As a front end developer you will create fast,responsive UIs that process large amounts of real time data and present it to our
        customers and partners using the latest technologies.
    </>
)

const feDevDescription: ReactFragment = (
    <>
        <JobDetailsList
            listTitle={'General'}
            listItems={
                <>
                    <p>Real time/performance oriented</p>
                    <p>Financial/trading interests/experience</p>
                </>
            }
        />
        <JobDetailsList
            listTitle={'Technical'}
            listItems={
                <>
                    <ul>
                        <li>React.js</li>
                        <li>
                            <ul className="nested-list">
                                <li>React.Redux</li>
                                <li>Static type checking (TypeScript, Flow)</li>
                            </ul>
                        </li>
                        <li>React.Hooks experience is a plus</li>
                        <li>Experience with low latency APIs (Websockets, GraphQL)</li>
                        <li>Experience with canvas drawing and/or D3.js is a plus</li>
                        <li>Experience with React.Native is a plus</li>
                    </ul>
                </>
            }
        />
        <JobDetailsList
            listTitle={'Responsibilities'}
            listItems={
                <>
                    <ul>
                        <li>Creating React UI components</li>
                        <li>
                            <ul className="nested-list">
                                <li>Implementing component behavior</li>
                                <li>Writing component unit tests</li>
                            </ul>
                        </li>
                        <li>Using React UI components to create a single page app</li>
                        <li>
                            <ul className="nested-list">
                                <li>Responsive design using Flexbox</li>
                                <li>Writing automated tests</li>
                            </ul>
                        </li>
                        <li>Implement data visualization components (charts)</li>
                    </ul>
                </>
            }
        />
    </>
)

const srEngineerFragment: ReactFragment = (
    <>
        As a Site Reliability Engineer (SRE) at Inceptive, you will be joining a highly performant R&D team with a focus on testing and
        maintaining lab and testing environments. This role involves working on provisioning and optimizing the infrastructure for our
        services and getting the infrastructure-as-code (IaC) ready for pre-production testing and future production deployment. You will be
        working closely with application and system developers to ensure that our product is meeting the highest standards of reliability
        and performance. The work done now will serve as the foundation for our production environment, and will need to be designed to
        scale within a larger operations team.
    </>
)

const srEngineerDescription: ReactFragment = (
    <>
        <JobDetailsList
            listTitle={'Responsibilities'}
            listItems={
                <>
                    <ul>
                        <li>Infrastructure Monitoring</li>
                        <ul className="nested-list">
                            <li>Develop and maintain hardware infrastructure monitoring system with alerting.</li>
                            <li>Develop and maintain database monitoring systems based on OTEL.</li>
                            <li>Develop and maintain application metrics collection and monitoring systems.</li>
                        </ul>

                        <li>Deployment and Automation</li>
                        <ul className="nested-list">
                            <li>Maintenance and provisioning of lab servers and the testing environment.</li>
                            <li>Configure and manage the network in the office/lab environments.</li>
                            <li>Maintain the CI/CD infrastructure.</li>
                        </ul>

                        <li>Performance Optimization</li>
                        <ul className="nested-list">
                            <li>Kernel parameter tuning for eliminating latency overhead and jitter in applications.</li>
                            <li>Conduct load testing and capacity planning.</li>
                        </ul>

                        <li>Infrastructure Provisioning (IaC)</li>
                        <ul className="nested-list">
                            <li>
                                Develop and maintain infrastructure-as-code (IaC) solutions for reproducible provisioning of the production
                                environment.
                            </li>
                            <li>Use tools like Ansible and Helm to provision the infrastructure.</li>
                            <li>Provision and support of the computing infrastructure and settings.</li>
                        </ul>
                    </ul>
                </>
            }
        />

        <JobDetailsList
            listTitle={'Requirements'}
            listItems={
                <>
                    <ul>
                        <li>Professional Experience</li>
                        <ul className="nested-list">
                            <li>Proven track record in SRE, DevOps, or system admin roles.</li>
                            <li>Experience managing hardware setups and coordinating with data center providers.</li>
                            <li>Strong analytical and problem-solving abilities.</li>
                            <li>Excellent communication and collaboration skills.</li>
                        </ul>

                        <li>Technical Skills</li>
                        <ul className="nested-list">
                            <li>Strong experience with Linux systems administration.</li>
                            <li>Knowledge of networking fundamentals.</li>
                            <li>Hands-on experience with automation and scripting tools, such as Ansible.</li>
                            <li>Knowledge of Docker and containerization.</li>
                            <li>Experience with orchestration tools such as Kubernetes or Hashicorp Nomad.</li>
                            <li>Experience with monitoring tools like Grafana, Prometheus, and Loki.</li>
                        </ul>

                        <li>Bonus skills</li>
                        <ul className="nested-list">
                            <li>Database administration.</li>
                            <li>Hardware and OS tuning knowledge.</li>
                            <li>Experience with low-latency system optimization and performance tuning.</li>
                        </ul>
                    </ul>
                </>
            }
        />
    </>
)

const talentWantedFragment: ReactFragment = (
    <>
        These job listings are incomplete. If you don&#39;t see your specialty listed here, but you have years of professional experience,
        are really talented at what you do, and possess a set of skills which would help us create better products and experiences for our
        customers, we hope you&#39;ll apply below.
    </>
)

export const determineJobSummary = (jobUrl: JobUrlType): ReactFragment =>
    match<JobUrlType, ReactFragment>(jobUrl)
        .with(JobUrl.CORE_PLATFORM_CPP, () => corePlatformCppFragment)
        .with(JobUrl.CORE_PLATFORM_GO, () => corePlatformGoFragment)
        .with(JobUrl.FE_DEVELOPER, () => feDevFragment)
        .with(JobUrl.SR_ENGINEER, () => srEngineerFragment)
        .otherwise(() => talentWantedFragment)

export const determineJobDescription = (jobUrl: JobUrlType): ReactFragment =>
    match<JobUrlType, ReactFragment>(jobUrl)
        .with(JobUrl.CORE_PLATFORM_CPP, () => corePlatformCppDescription)
        .with(JobUrl.CORE_PLATFORM_GO, () => corePlatformGoDescription)
        .with(JobUrl.FE_DEVELOPER, () => feDevDescription)
        .with(JobUrl.SR_ENGINEER, () => srEngineerDescription)
        .otherwise(() => <></>)
